<template>
  <div class="user-roles">
    <div class="page-header">
      <h2>用户角色管理</h2>
      <div>
        <el-button @click="$router.push('/organization')">返回组织</el-button>
      </div>
    </div>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input
          v-model="listQuery.search"
          placeholder="请输入用户名/ID"
          style="width: 200px;"
          class="filter-item"
          @keyup.enter.native="handleFilter"
        />
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
          搜索
        </el-button>
      </div>

      <el-table
        v-loading="listLoading"
        :data="userList"
        element-loading-text="加载中..."
        border
        fit
        highlight-current-row
        style="width: 100%;"
      >
        <el-table-column prop="id" label="ID" align="center" width="80" />
        <el-table-column prop="userid" label="用户ID" align="center" width="120" />
        <el-table-column prop="username" label="用户名" align="center" min-width="120" />
        <el-table-column prop="department" label="部门" align="center" min-width="120" />
        <el-table-column prop="post" label="职位" align="center" min-width="120" />
        <el-table-column prop="role_name" label="当前角色" align="center" min-width="120">
          <template slot-scope="{row}">
            <el-tag v-if="row.role_id" type="success">{{ row.role_name }}</el-tag>
            <span v-else>未分配</span>
          </template>
        </el-table-column>
        <el-table-column prop="last_login" label="最后登录时间" align="center" width="180" />
        <el-table-column label="操作" align="center" width="180" class-name="small-padding fixed-width">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="handleSetRole(row)">
              设置角色
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.page_size"
        @pagination="getUserList"
      />
    </el-card>

    <!-- 设置用户角色对话框 -->
    <el-dialog title="设置用户角色" :visible.sync="roleDialogVisible" width="400px">
      <el-form :model="roleForm" label-width="100px">
        <el-form-item label="用户名">
          <span>{{ currentUser ? currentUser.username : '' }}</span>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="roleForm.role_id" placeholder="请选择角色" style="width: 100%">
            <el-option
              v-for="item in roleOptions"
              :key="item.id"
              :label="item.role_name"
              :value="item.id"
            />
            <el-option :value="null" label="无角色" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveUserRole" :loading="saveLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUsersWithRoles, setUserRole, getRoles } from '@/api'
import Pagination from '@/components/Pagination'

export default {
  name: 'UserRoles',
  components: { Pagination },
  data() {
    return {
      userList: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        page_size: 10,
        search: ''
      },
      roleDialogVisible: false,
      roleForm: {
        role_id: null
      },
      currentUser: null,
      roleOptions: [],
      saveLoading: false
    }
  },
  created() {
    this.getUserList()
    this.fetchRoles()
  },
  methods: {
    async getUserList() {
      this.listLoading = true
      try {
        const res = await getUsersWithRoles(this.listQuery)
        if (res.code === 200) {
          this.userList = res.data.users
          this.total = res.data.total
        }
      } catch (error) {
        console.error('获取用户列表失败:', error)
        this.$message.error('获取用户列表失败')
      } finally {
        this.listLoading = false
      }
    },
    async fetchRoles() {
      try {
        const res = await getRoles({ page: 1, page_size: 100 })
        if (res.code === 200) {
          this.roleOptions = res.data.roles
        }
      } catch (error) {
        console.error('获取角色列表失败:', error)
        this.$message.error('获取角色列表失败')
      }
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getUserList()
    },
    handleSetRole(row) {
      this.currentUser = row
      this.roleForm.role_id = row.role_id
      this.roleDialogVisible = true
    },
    async saveUserRole() {
      if (!this.currentUser) return;
      
      this.saveLoading = true;
      try {
        const userId = this.currentUser.userid || this.currentUser.id;
        const res = await setUserRole(userId, { role_id: this.roleForm.role_id });
        if (res.code === 200) {
          this.$message.success('用户角色设置成功');
          this.roleDialogVisible = false;
          this.getUserList();
        }
      } catch (error) {
        console.error('设置用户角色失败:', error);
        this.$message.error('设置用户角色失败');
      } finally {
        this.saveLoading = false;
      }
    }
  }
}
</script>

<style scoped>
.user-roles {
  padding: 20px;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.page-header div {
  display: flex;
  gap: 10px;
}

.filter-container {
  margin-bottom: 20px;
}

.filter-item {
  margin-right: 10px;
}
</style> 